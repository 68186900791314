@import './0_palette';
@import './1_variables';

.error {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url('/static/images/error-page-desc.svg');
  background-size: contain;
  background-color: $p-bg-white;
  color: $p-color;
  text-align: center;
  font-family: $helvetica;
  padding-top: 20px;

  &-status {
    font-size: 210px;
    font-weight: 600;
    line-height: 176px;
    letter-spacing: 3.9px;
    margin: 35px 0;
  }

  &-text {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: $p-color;
    padding: 0 10px;
  }

  &-link {
    display: inline-block;
    border-radius: 4px;
    background: $p-btn;
    margin: 40px auto 0;
    padding: 0 20px;
    color: $p-color-white;
    font-family: $helvetica;
    font-size: 15px;
    line-height: 50px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-decoration: none;
  }
}

@media screen and (max-width: 480px) {
  .error {
    background-image: url('/static/images/error-page.svg');
    background-size: contain;

    &-status {
      font-size: 140px;
      line-height: 176px;
      margin: 25px 0 0;
    }

    &-text {
      margin-top: 0;
      font-size: 22px;
    }

    &-link {
      margin: 47px auto 0;
    }
  }
}
