@import './0_palette';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 7px;
  background-color: $p-btn;
  border: 0;
  color: $p-color-white;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;

  &.disabled {
    background-color: $p-btn-disabled;
    pointer-events: none;

    .loading & {
      background-color: $p-btn;
    }
  }
}
