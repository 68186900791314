@import './0_palette';

@mixin pseudo-cross-icon($left, $top) {
  &::before,
  &::after {
    content: ' ';
    height: 18px;
    width: 2px;
    position: absolute;
    left: $left;
    top: $top;
    background-color: $p-icon;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
