@import './0_palette';
@import './2_mixins';

.auth {
  width: 455px;
  padding: 40px 55px;
  border-radius: 7px;
  background-color: $p-bg-white;
  text-align: center;
  position: relative;
  overflow-y: auto;

  &__close-btn {
    @include pseudo-cross-icon(16px, 8px);
    display: inline-block;
    position: fixed;
    top: 50px;
    right: 50px;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    border: 0;
    box-sizing: border-box;
    color: transparent;
    font-size: 0;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }
}
