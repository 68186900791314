$p-bg: #f6f7fa;
$p-bg-white: #fff;
$p-icon: #b1b8c6;
$p-btn: #4c88f9;
$p-btn-disabled: #a5c3fc;
$p-color-white: #fff;
$p-color: #212c3a;
$p-color-light: #8d94ad;
$p-border: #eaebf2;
$p-error: #ff586d;
