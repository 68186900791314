.loader {
  $size: 20px;
  width: $size;
  height: $size;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMS45ODYiIHk9IjMuODcyIiB3aWR0aD0iMi42NjciIGhlaWdodD0iNi42NjciIHJ4PSIxLjMzMyIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDEuOTg2IDMuODcyKSIgZmlsbD0iI0RCRTBFOSIgZmlsbC1vcGFjaXR5PSIuOSIvPjxyZWN0IHg9IjYuNjY3IiB5PSI4LjY2NyIgd2lkdGg9IjIuNjY3IiBoZWlnaHQ9IjYuNjY3IiByeD0iMS4zMzMiIHRyYW5zZm9ybT0icm90YXRlKDkwIDYuNjY3IDguNjY3KSIgZmlsbD0iI0RCRTBFOSIgZmlsbC1vcGFjaXR5PSIuNyIvPjxyZWN0IHg9IjYuNyIgeT0iMTEuNDE0IiB3aWR0aD0iMi42NjciIGhlaWdodD0iNi42NjciIHJ4PSIxLjMzMyIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNi43IDExLjQxNCkiIGZpbGw9IiNEQkUwRTkiIGZpbGwtb3BhY2l0eT0iLjYiLz48cmVjdCB4PSI4LjY2NyIgeT0iMTMuMzMzIiB3aWR0aD0iMi42NjciIGhlaWdodD0iNi42NjciIHJ4PSIxLjMzMyIgZmlsbD0iI0RCRTBFOSIgZmlsbC1vcGFjaXR5PSIuNCIvPjxyZWN0IHg9IjExLjQxNCIgeT0iMTMuMyIgd2lkdGg9IjIuNjY3IiBoZWlnaHQ9IjYuNjY3IiByeD0iMS4zMzMiIHRyYW5zZm9ybT0icm90YXRlKC00NSAxMS40MTQgMTMuMykiIGZpbGw9IiNEQkUwRTkiIGZpbGwtb3BhY2l0eT0iLjMiLz48cmVjdCB4PSIyMCIgeT0iOC42NjciIHdpZHRoPSIyLjY2NyIgaGVpZ2h0PSI2LjY2NyIgcng9IjEuMzMzIiB0cmFuc2Zvcm09InJvdGF0ZSg5MCAyMCA4LjY2NykiIGZpbGw9IiNEQkUwRTkiIGZpbGwtb3BhY2l0eT0iLjIiLz48cmVjdCB4PSIxNi4xMjgiIHk9IjEuOTg2IiB3aWR0aD0iMi42NjciIGhlaWdodD0iNi42NjciIHJ4PSIxLjMzMyIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgMTYuMTI4IDEuOTg2KSIgZmlsbD0iI0RCRTBFOSIgZmlsbC1vcGFjaXR5PSIuMSIvPjxyZWN0IHg9IjguNjY3IiB3aWR0aD0iMi42NjciIGhlaWdodD0iNi42NjciIHJ4PSIxLjMzMyIgZmlsbD0iI0RCRTBFOSIvPjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-size: cover;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
