@import './0_palette';
@import './2_mixins';

@media screen and (max-width: 414px) {
  .main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: initial;
    justify-content: flex-start;
    background-color: $p-bg-white;
  }

  .auth {
    width: 100%;
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    &__close-btn {
      display: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    margin-bottom: 30px;
    border-bottom: 1px solid $p-border;
    position: relative;

    &__title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  .btn {
    width: calc(100% - 15px * 2);
    margin: auto;
  }

  .back-btn {
    width: 40px;
    height: 100%;
    left: 0;
    top: 0;

    .login-by-phone & {
      @include pseudo-cross-icon(21px, 13px);
      display: inline-block;
      width: 44px;

      &::after {
        border-right: 0;
        border-bottom: 0;
        margin-left: 0;
      }
    }
  }

  .repeat-sms-code-request {
    font-size: 16px;
    line-height: 19px;
  }

  .input-group {
    $ig: &;

    padding: 0 15px;

    &__placeholder {
      left: 15px;
      line-height: 17px;
    }

    &__field {
      height: 55px;
      padding: 30px 0 8px;
      letter-spacing: initial;
    }
  }

  .message {
    &--info {
      margin: 0 15px 5px;
    }

    &--error {
      margin: 0 15px 20px;
    }
  }
}
