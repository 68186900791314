@import './0_palette';
@import './1_variables';

.input-group {
  $input-group: &;

  position: relative;
  margin-bottom: 20px;

  &__placeholder {
    position: absolute;
    top: 8.5px;
    left: 0;
    pointer-events: none;
    font-size: 14px;
    line-height: 17px;
    color: $p-color-light;
  }

  &__field {
    height: 55px;
    padding: 27px 0 8px;
    width: 100%;
    color: $p-color;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 1px;
    font-family: $helvetica;
    background-color: $p-bg-white;
    border: 0;
    border-bottom: 1px solid $p-border;
    box-sizing: border-box;
    box-shadow: none;
    appearance: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .loading & {
    opacity: 0.5;
  }

  &.has-error {
    #{$input-group}__field {
      border-color: $p-error;
    }

    .message--error {
      margin: 2px 0 0;
      text-align: left;
      display: block;
      padding: 0;
    }
  }
}
