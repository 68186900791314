@import './0_palette';

.message {
  font-size: 14px;

  &--info {
    color: $p-color-light;
    text-align: left;
    margin-bottom: 5px;
  }

  &--error {
    display: inline-block;
    margin-bottom: 20px;
    color: $p-error;
  }
}
